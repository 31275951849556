import { WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import _ from "lodash";
import moment from "moment";
import momentTimezone from "moment-timezone";

const twoDecimalRound = (number) => Math.round((number + Number.EPSILON) * 100) / 100;

export const calculateStripeFee = (price) => {
  if (!price) {
    return 0;
  }
  const stripeTotal = twoDecimalRound((price + 0.3) / 0.971);
  const fee = stripeTotal - price;
  return twoDecimalRound(fee);
};

export const calculateStringHash = (s) =>
  s.split("").reduce((a, b) => {
    // eslint-disable-next-line no-bitwise
    const x = (a << 5) - a + b.charCodeAt(0);
    // eslint-disable-next-line no-bitwise
    return x & x;
  }, 0);

export const kebabToCamel = (string) => {
  const camelizedArray =
    string
      ?.split("-")
      .map((item, index) =>
        index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase(),
      ) || [];
  return camelizedArray.join("");
};

export const snakeToCamel = (obj) => {
  return _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);

    acc[camelKey] = _.isObject(value) ? snakeToCamel(value) : value;
  });
};

export const camelToSnake = (obj) => {
  return _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.snakeCase(key);

    acc[camelKey] = _.isObject(value) ? camelToSnake(value) : value;
  });
};

export const normalizeCredits = (credit) => {
  const str = `${parseInt(credit || 0, 10)}`;
  const size = Math.ceil(str.length / 3);

  let offset = str.length;
  let result = "";

  for (let i = size - 1; i >= 0; i -= 1) {
    result = `${str.substr(Math.max(offset - 3, 0), offset)}${result && "."}${result}`;
    offset -= 3;
  }

  return result;
};

const GB_TO_MB = 1073741824;
export const MB_TO_BYTE = 1048576;

export const bytesToGB = (bytes) => {
  return bytes / GB_TO_MB;
};

export const gbToBytes = (gb) => {
  const BYTES_IN_GB = 1073741824;
  return gb * BYTES_IN_GB;
};

export const bytesToSize = (bytes) => {
  if (bytes === 0) return "0";
  if (!bytes) {
    return "";
  }
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const doesEventContainsFiles = (event) => {
  if (!event.dataTransfer) {
    return false;
  }

  if (event.dataTransfer.types) {
    for (let i = 0; i < event.dataTransfer.types.length; i += 1) {
      if (event.dataTransfer.types[i] === "Files") {
        return true;
      }
    }
  }

  return false;
};

export const shortenFileName = (fileName, characterLimit = 20) => {
  if (!fileName) {
    return "";
  }
  if (fileName.length < characterLimit + 3) {
    return fileName;
  }

  return `${fileName.slice(0, characterLimit)}...`;
};

// in milliseconds
const TIME_UNITS = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getDate = (time) => {
  return `${time.getDate()} ${MONTHS[time.getMonth()]} ${time.getFullYear()}`;
};

export const relativeTime = (previous, userTimeZone = null) => {
  const current = Date.now();
  const elapsed = current - new Date(previous).getTime();

  if (elapsed < 2 * TIME_UNITS.minute) {
    return "1 minute ago";
  } else if (elapsed < TIME_UNITS.hour) {
    return `${Math.round(elapsed / TIME_UNITS.minute)} minutes ago`;
  } else if (elapsed < 2 * TIME_UNITS.hour) {
    return "1 hour ago";
  } else if (elapsed < TIME_UNITS.day) {
    return `${Math.round(elapsed / TIME_UNITS.hour)} hours ago`;
  } else if (elapsed < 2 * TIME_UNITS.day) {
    return "1 day ago";
  } else if (elapsed < 6 * TIME_UNITS.day) {
    return `${Math.round(elapsed / TIME_UNITS.day)} days ago`;
  }

  return convertToSelectedTimeZone(
    `${convertDateFormat(previous.substring(0, 10))} ${previous.substring(
      previous.indexOf("T") + 1,
      previous.indexOf("T") + 6,
    )}`,
    userTimeZone,
  );

  //  else if (elapsed < TIME_UNITS.year) {
  //   return `${Math.round(elapsed / TIME_UNITS.month)} months ago`;
  // }

  // return `${Math.round(elapsed / TIME_UNITS.year)} years ago`;
};

export const classnames = (classNames) => {
  if (Array.isArray(classNames)) {
    return classNames
      .filter(Boolean) // Remove falsy values from classname list to avoid redundant spaces.
      .join(" ");
  }
  const classes = [];
  const keys = Object.keys(classNames);

  for (let i = 0; i < keys.length; i += 1) {
    const arg = keys[i];
    if (arg) {
      const argType = typeof arg;

      if (argType === "string" || argType === "number") {
        classes.push(arg);
      } else if (Array.isArray(arg)) {
        if (arg.length) {
          const inner = classNames.spread(null, arg);
          if (inner) {
            classes.push(inner);
          }
        }
      } else if (argType === "object") {
        if (arg.toString === Object.prototype.toString) {
          Object.keys(arg).forEach((key) => {
            if (arg[key]) {
              classes.push(key);
            }
          });
        } else {
          classes.push(arg.toString());
        }
      }
    }
  }

  return classes.join(" ");
};

export const isProduction = () => {
  return (
    !process.env.REACT_APP_API_BASE_URL.includes("staging") && !process.env.REACT_APP_API_BASE_URL.includes("sandbox")
  );
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const convertDateFormat = (dateString) => {
  if (!dateString) return null;

  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

export const localDateFormat = (date) => {
  if (!date) return null;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
};

export const formatMultiWordString = (stringToFormat) => {
  const temp = stringToFormat.replace(/_/g, " ").split(" ");
  for (let i = 0; i < temp.length; i += 1) {
    temp[i] = temp[i].charAt(0).toUpperCase() + temp[i].substring(1);
  }
  return temp.join(" ");
};

export const getHoursFromDateString = (dateString) => {
  if (dateString) return new Date(dateString).getHours().toString().padStart(2, "0");
  return "00";
};

export const getMinutesFromDateString = (dateString) => {
  if (dateString) return new Date(dateString).getMinutes().toString().padStart(2, "0");
  return "00";
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const groupMachineTypes = (machineTypes) => {
  return _.reduce(
    machineTypes,
    (group, machineType) => {
      const { name, friendly_name: friendlyName } =
        machineType?.attributes?.details?.attributes || machineType.attributes;
      if (name.startsWith("g5")) {
        return {
          ...group,
          a10: {
            ...group.a10,
            [machineType.id]: machineType,
          },
        };
      } else if (name.startsWith("g4dn")) {
        return {
          ...group,
          gpu: {
            ...group.gpu,
            [machineType.id]: machineType,
          },
        };
      } else if (friendlyName === "Sand") {
        return {
          ...group,
          general: {
            ...group.general,
            [machineType.id]: machineType,
          },
        };
      }

      return {
        ...group,
        cpu: {
          ...group.cpu,
          [machineType.id]: machineType,
        },
      };
    },
    {},
  );
};

export const groupOrganizationMachineTypes = (machineTypes, excludeGeneral = false) => {
  return _.reduce(
    machineTypes,
    (group, machineType) => {
      const { name, friendly_name: friendlyName } = machineType.attributes;
      if (name.startsWith("g5")) {
        return {
          ...group,
          a10: {
            ...group.a10,
            [machineType.id]: machineType,
          },
        };
      } else if (name.startsWith("g4dn")) {
        return {
          ...group,
          gpu: {
            ...group.gpu,
            [machineType.id]: machineType,
          },
        };
      } else if (!excludeGeneral && friendlyName === "Sand") {
        return {
          ...group,
          general: {
            ...group.general,
            [machineType.id]: machineType,
          },
        };
      }

      return {
        ...group,
        cpu: {
          ...group.cpu,
          [machineType.id]: machineType,
        },
      };
    },
    {},
  );
};

export const groupMachineTypeUsages = (machineTypeUsages) => {
  return _.reduce(
    machineTypeUsages,
    (group, machineTypeUsage) => {
      const { machine_type: machineType } = machineTypeUsage.attributes;
      const { name, friendly_name: friendlyName } = machineType.attributes;
      if (name.startsWith("g5")) {
        return {
          ...group,
          a10: {
            ...group.a10,
            [machineType.id]: {
              ...machineType,
              readableUsage: machineTypeUsage.attributes.readable_usage,
              available: machineTypeUsage.attributes.available,
            },
          },
        };
      } else if (name.startsWith("g4dn")) {
        return {
          ...group,
          gpu: {
            ...group.gpu,
            [machineType.id]: {
              ...machineType,
              readableUsage: machineTypeUsage.attributes.readable_usage,
              available: machineTypeUsage.attributes.available,
            },
          },
        };
      } else if (friendlyName === "Sand") {
        return {
          ...group,
          general: {
            ...group.general,
            [machineType.id]: {
              ...machineType,
              readableUsage: machineTypeUsage.attributes.readable_usage,
              available: machineTypeUsage.attributes.available,
            },
          },
        };
      }

      return {
        ...group,
        cpu: {
          ...group.cpu,
          [machineType.id]: {
            ...machineType,
            readableUsage: machineTypeUsage.attributes.readable_usage,
            available: machineTypeUsage.attributes.available,
          },
        },
      };
    },
    {},
  );
};

export function isHighContrast(hex) {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128;
}

export const stringToBoolean = (stringValue) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false;

    default:
      return JSON.parse(stringValue);
  }
};

export const secondsToMinutesSeconds = (seconds) => {
  const format = (val) => `0${Math.floor(val)}`.slice(-2);
  const minutes = (seconds % 3600) / 60;

  return [minutes, seconds % 60].map(format).join(":");
};

export const dolarToCents = (dolar, fractionDigits = 1) => {
  const cents = dolar * 100;
  const floated = parseFloat(cents.toFixed(fractionDigits));
  return String(floated);
};

export const isOdd = (num) => {
  return num % 2 === 1;
};

export const isEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const joinWithCommaAnd = (arr) => {
  if (arr.length === 1) return arr[0];
  const firstElements = arr.slice(0, -1); // Get all elements except the last one
  const lastElement = arr.slice(-1); // Get the last element
  return `${firstElements.join(", ")} and ${lastElement}`;
};

export const joinWithComma = (arr) => {
  if (arr.length === 1) return arr[0];
  const firstElements = arr.slice(0, -1); // Get all elements except the last one
  const lastElement = arr.slice(-1); // Get the last element
  return `${firstElements.join(", ")}${firstElements.length ? ", " : ""}${lastElement}`;
};

export const minutesToHours = (seconds) => {
  const hours = seconds / 60; // There are 3600 seconds in an hour
  return Number(hours.toFixed(1)); // Round to 1 decimal place and convert to number
};

export const pollingIntervalByFriendlyStatus = (friendlyStatus) => {
  switch (friendlyStatus) {
    case WORKSTATION_FRIENDLY_STATUS.CREATING:
      return 5000;
    case WORKSTATION_FRIENDLY_STATUS.TURNING_ON:
      return 5000;
    case WORKSTATION_FRIENDLY_STATUS.READY:
      return 15000;
    case WORKSTATION_FRIENDLY_STATUS.TURNING_OFF:
      return 5000;
    case WORKSTATION_FRIENDLY_STATUS.OFF:
      return 15000;
    default:
      return 5000;
  }
};

const setFormatString = (duration) => {
  let formatString = "";
  if (duration.hours() > 0) {
    formatString += "H [hours] ";
  }
  if (duration.minutes() > 0) {
    formatString += "m [minutes]";
  }
  return formatString;
};

export const humanizeMinutes = (minutes) => {
  const duration = moment.duration(minutes, "minutes");

  return moment.utc(duration.asMilliseconds()).format(setFormatString(duration));
};

export const minutesToReadableHoursMinutes = (totalMinutes) => {
  const duration = moment.duration(totalMinutes, "minutes");
  const hours = duration.hours() + duration.days() * 24;
  const minutes = duration.minutes();

  return `${hours ? `${hours} hours` : ""}${hours && minutes ? " " : ""}${minutes ? `${minutes} minutes` : ""}`;
};

export const minutesToReadableHoursMinutesSeconds = (minutes) => {
  // Convert minutes to seconds
  let totalSeconds = Math.floor(minutes * 60);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const mins = Math.floor(totalSeconds / 60);
  const secs = totalSeconds % 60;

  // Format the result as a string
  const hoursStr = hours > 0 ? `${hours} hours ` : "";
  const minsStr = mins > 0 ? `${mins} minutes ` : "";
  const secsStr = hours <= 0 && secs > 0 ? `${secs} seconds` : "";

  return `${hoursStr}${minsStr}${secsStr}`.trim();
};

export const secondsToTime = (seconds) => {
  const momentDuration = moment.duration(seconds, "seconds");

  return `${String(Math.floor(momentDuration.asHours())).padStart(2, "0")}:${String(momentDuration.minutes()).padStart(
    2,
    "0",
  )}:${String(momentDuration.seconds()).padStart(2, "0")}`;
};

export const pluralize = (count, noun, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const humanFileSize = (bytes, dp = 1) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let u = -1;
  const r = 10 ** dp;

  let updatedBytes = bytes;

  do {
    updatedBytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(updatedBytes) * r) / r >= thresh && u < units.length - 1);

  return `${updatedBytes.toFixed(dp)} ${units[u]}`;
};

export const convertToSelectedTimeZone = (utcDate, timeZone) => {
  return momentTimezone
    .utc(utcDate, "DD-MM-YYYY HH:mm")
    .tz(timeZone === null ? "UTC" : timeZone)
    .format("DD/MM/YYYY - HH:mm");
};
